<template>
  <div class="pt-2">
    <cols-organizer :cols="1" :sm="2" :md="3" :items="sortedResults">
      <template v-slot="{ item: party }">
        <party-item :timeStatus="false" :party="party"></party-item>
      </template>
    </cols-organizer>

    <v-alert v-if="!isAuthenticated" prominent type="info" class="mt-8" text>
      <div class="d-flex flex-wrap justify-space-between align-center">
        <div>
          <b>Não encontrou o evento que procurava?</b>
          <br />
          Tente entrar na sua conta para ver eventos que você foi convidado.
        </div>
        <v-btn color="primary" @click="login">entrar</v-btn>
      </div>
    </v-alert>
  </div>
</template>

<script>
import SHOP from "@/services/shop";
import { mapGetters } from "vuex";
import ColsOrganizer from "@/components/global/ColsOrganizer.vue";
import PartyItem from "@/components/shop/PartyItem.vue";
import PartySort from "@/utils/partySortFunction";

export default {
  metaInfo: {
    title: "Eventos",
  },
  data: () => ({
    parties: [],
    loading: false,
    error: null,
    results: [],
  }),
  methods: {
    async getParty() {
      try {
        this.loading = true;
        const { parties } = await SHOP.highlight();
        this.parties = parties.map((party) => ({
          ...party,
          path: `/shop/${party.Organization.slug}/${party.slug || party.id}`,
        }));
        this.results = this.parties.sort(PartySort);
      } catch (error) {
        this.error = error.message || error;
      } finally {
        this.loading = false;
      }
    },
    login() {
      this.$router.push({ name: "sign-in", query: { redirect: "/shop" } });
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    sortedResults() {
      return this.results.sort(PartySort);
    },
  },
  components: { ColsOrganizer, PartyItem },
  mounted() {
    this.getParty();
  },
};
</script>

<style></style>
