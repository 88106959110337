<template>
  <iframe
    v-if="valid"
    :src="`https://open.spotify.com/embed/${id}`"
    width="100%"
    :height="spotifyTypeHeight[spotifyType] || 380"
    frameborder="0"
    allowtransparency="true"
  />
</template>

<script>
export default {
  data() {
    return {
      spotifyTypeHeight: {
        artist: 360,
        album: 360,
        playlist: 360,
        track: 80,
      },
      validSpotifyRegex: /((album|playlist|track|artist)\/)\S*/g,
    };
  },
  computed: {
    valid() {
      const id = this.id || "";
      return !!id.match(this.validSpotifyRegex);
    },
    spotifyType() {
      const id = this.id || "";
      const match = id.split("/");

      return match[0];
    },
  },
  props: {
    id: {
      type: String,
    },
  },
};
</script>

<style>
</style>