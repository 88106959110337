import moment from "moment";
import pStatus from "./paymentStatus";
export default class PartyStatus {
  constructor(filiation) {
    this.filiation = filiation;
    return this.status;
  }
  get lastPurchase() {
    return this.filiation.MembershipPurchase.sort((a, b) =>
    moment(b.createdAt).diff(moment(a.createdAt))
    )[0]
  }
  
  get status() {
    const lastPurchase = this.lastPurchase;
    if (!lastPurchase) return "inactive";
    const paymentStatus = new pStatus(lastPurchase.Payment);
    if (
      paymentStatus.status === "pending" ||
      paymentStatus.status === "pending_action"
    )
      return "pending";
    if (paymentStatus.status === "canceled") return "inactive";

    if (moment(lastPurchase.validFrom).isAfter(moment())) return "pending";
    if (moment(lastPurchase.validUntil).isBefore(moment())) return "expired";
    return "active";
  }
}
