<template>
  <div class="pt-4">
    <div
      class="d-flex gap-4 flex-wrap justify-center align-start"
      v-if="filiation"
    >
      <v-card
        class="white--text d-flex justify-center"
        :style="{
          borderRadius: '10px',
          border: `3px solid ${filiation.Membership.backgroundColor || '#000'}`,
          boxShadow: `0 0 10px  ${
            filiation.Membership.backgroundColor || 'rgba(0,0,0,.2)'
          }`,
          background: `url('${filiation.Membership.backgroundImg}'), ${
            filiation.Membership.backgroundColor || '#000'
          }`,
          backgroundSize: 'cover',
        }"
      >
        <v-responsive
          :aspect-ratio="5.4 / 8.5"
          width="350px"
          style="background-color: rgba(0, 0, 0, 0.7)"
        >
          <div class="d-flex flex-column justify-space-between h-full pa-4">
            <div class="d-flex gap-4">
              <h3 class="white--text text-center">
                {{ filiation.Membership.name }}
              </h3>
            </div>
            <div
              class="d-flex flex-column text-16 gap-2 pa-4 px-2 rounded-lg"
              style="background: rgba(0, 0, 0, 0.9)"
            >
              <div
                class="d-flex gap-4"
                v-for="(benefit, i) in benefits"
                :key="i"
              >
                <v-icon color="yellow">mdi-star</v-icon>
                <span>{{ benefit }}</span>
              </div>
            </div>
            <div class="d-flex justify-space-between align-end">
              <v-chip
                label
                :color="chipStatus[lastPurchase.status].color"
                style="height: 48px"
              >
                <b v-if="lastPurchase.status == 'active'">
                  Válido até <br />
                  {{ lastPurchase.validUntil | date }}
                </b>
                <b v-else-if="lastPurchase.status == 'expired'">
                  Vencido em <br />
                  {{ lastPurchase.validUntil | date }}
                </b>
                <template v-else>{{
                  chipStatus[lastPurchase.status].text
                }}</template>
              </v-chip>
              <v-btn
                v-if="lastPurchase.status == 'active'"
                @click="qrCodeModal"
                color="white"
                fab
                small
              >
                <v-icon color="black">mdi-qrcode</v-icon>
              </v-btn>
            </div>
          </div>
        </v-responsive>
      </v-card>
      <div class="flex-grow-1 d-flex flex-column gap-2">
        <v-card elevation="0" class="pa-4 rounded-lg">
          <h6>Pagamentos</h6>
          <filiation-payments :filiation="filiation" />
        </v-card>
        <v-card elevation="0" class="pa-4 rounded-lg" v-if="partners.length">
          <div class="d-flex align-center gap-6">
            <h6 class="mb-0">Parceiros</h6>
            <v-text-field
              v-model="partnerSearch"
              hide-details
              label="Pesquisar"
              outlined
              dense
            />
          </div>
          <partners :search="partnerSearch" :partners="partners" />
        </v-card>
      </div>
    </div>
    <v-progress-linear v-else indeterminate />
    <membership-card-modal ref="membershipModal" />
  </div>
</template>

<script>
import MEMBERSHIP from "@/services/app/membership";
import pStatus from "@/utils/paymentStatus";
import moment from "moment";
import MembershipCardModal from "../../../components/global/MembershipCardModal.vue";
import { mapState } from "vuex";
import Partners from "../../../components/app/membership/Partners.vue";
import FiliationPayments from "../../../components/app/membership/FiliationPayments.vue";
export default {
  components: { MembershipCardModal, Partners, FiliationPayments },
  data: () => ({
    filiation: null,
    partners: [],
    partnerSearch: "",
    lastPurchase: null,
    chipStatus: {
      active: {
        color: "success",
        text: "Ativo",
      },
      pending: {
        color: "info",
        text: "Pendente",
      },
      inactive: {
        color: "secondary",
        text: "Inativo",
      },
      expired: {
        color: "error",
        text: "Expirado",
      },
    },
  }),
  methods: {
    async getFiliation() {
      try {
        const { filiation, partners } = await MEMBERSHIP.getFiliationById(
          this.$route.params.id
        );
        const platform = filiation.Membership?.Organization?.Platform;
        if (platform) {
          const url = `https://${platform.domain}/app/membership/${filiation.id}`;
          window.location.replace(url);
          return;
        }

        this.filiation = filiation;
        this.partners = partners;
        this.setLastPurchase();
      } catch (error) {
        this.$router.push({ name: "app.membership" });
      }
    },
    setLastPurchase() {
      if (!this.filiation) return {};
      const purchases = this.filiation.MembershipPurchase.sort((a, b) => {
        return new Date(b.validUntil) - new Date(a.validUntil);
      });
      const [last] = purchases;
      const status = this.status(last);
      this.lastPurchase = {
        ...last,
        status,
      };
    },
    qrCodeModal() {
      this.$refs.membershipModal.open({ ...this.filiation, User: this.user });
    },
    status(purchase) {
      if (!purchase) return "inactive";
      const paymentStatus = new pStatus(purchase.Payment);
      if (
        paymentStatus.status === "pending" ||
        paymentStatus.status === "pending_action"
      )
        return "pending";
      if (paymentStatus.status === "canceled") return "inactive";

      if (moment(purchase.validFrom).isAfter(moment())) return "pending";
      if (moment(purchase.validUntil).isBefore(moment())) return "expired";
      return "active";
    },
  },
  computed: {
    ...mapState("auth", ["user"]),
    benefits() {
      if (!this.filiation) return [];
      return this.filiation.Membership.description.trim().split("\n");
    },
  },
  mounted() {
    this.getFiliation();
  },
};
</script>

<style></style>
