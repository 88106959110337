<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    overlay-opacity="0.9"
    @click:outside="close"
    :persistent="loading"
  >
    <v-card v-if="!!ticket && !succeeded">
      <v-card-title class="headline" v-if="!transferDisabled">
        Transferir Ingresso
      </v-card-title>
      <v-card-text class="pb-2">
        <template v-if="transferDisabled">
          <div class="text-center pt-8">
            <v-avatar class="mb-4" size="60" color="grey lighten-4">
              <v-icon x-large color="grey darken-2">{{
                transferDisabled.icon
              }}</v-icon>
            </v-avatar>
            <h6>{{ transferDisabled.text }}</h6>
          </div>
        </template>
        <template v-else>
          <v-alert type="warning" dense text border="left">
            <span class="mb-0">
              Ao transferir o ingresso, o processo é <b>irreversível</b> e o
              ingresso não poderá ser devolvido.
            </span>
          </v-alert>
          <phone-input
            :phone="whatsapp"
            @update:phone="(e) => (whatsapp = e)"
            :ddi="ddi"
            @update:ddi="(e) => (ddi = e)"
            @valid="(v) => (valid = v)"
            dense
            outlined
            label="WhatsApp do Destinatário"
            :disabled="loading"
          />
          <v-checkbox
            class="my-0 pt-0"
            v-model="check"
            :disabled="loading"
            label="Confirmo que o WhatsApp do destinatário está correto."
            :rules="[(v) => !!v || 'Confirmação é obrigatória']"
          />
          <v-alert type="error" v-if="error" class="mt-2 mb-0" border="left">
            {{ error }}
          </v-alert>
        </template>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          v-if="!transferDisabled"
          color="primary"
          @click="transfer"
          block
          :disabled="!valid || !check || loading"
          :loading="loading"
          class="mb-2"
        >
          Transferir
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="dialog = false"
          :disabled="loading"
          block
          class="ml-0"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="succeeded">
      <v-card-text>
        <div class="text-center pt-8">
          <v-avatar class="mb-4" size="60" color="success lighten-4">
            <v-icon x-large color="success darken-2">mdi-check</v-icon>
          </v-avatar>
          <h6>Ingresso transferido com sucesso!</h6>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          color="primary"
          text
          @click="$router.push({ name: 'app.ticket' })"
          block
          class="ml-0"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import TICKET from "@/services/app/ticket";
import { mapGetters } from "vuex";
import PhoneInput from "../../global/PhoneInput.vue";
export default {
  components: { PhoneInput },
  data: () => ({
    dialog: false,
    ticket: null,
    loading: false,
    valid: false,
    error: false,
    whatsapp: "",
    ddi: 55,
    check: false,
    succeeded: false,
  }),
  methods: {
    async transfer() {
      try {
        this.loading = true;
        this.error = false;
        await TICKET.transfer(this.ticket.id, {
          user: { phone: this.whatsapp, ddi: this.ddi },
        });
        this.succeeded = true;
      } catch (error) {
        this.error = error.message || "Erro ao transferir ingresso";
        this.check = false;
        this.loading = false;
      }
    },
    open(ticket) {
      this.ticket = ticket;
      this.error = false;
      this.succeeded = false;
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
      this.$emit("close");
      this.$nextTick(() => {
        this.whatsapp = "";
        this.ddi = 55;
        this.check = false;
        this.error = false;
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    transferDisabled() {
      if (!this.ticket) return { icon: "mdi-error", text: "Erro ao carregar" };
      if (this.ticket.Payment.paymentType === "COURTESY") {
        return {
          icon: "mdi-account-star",
          text: "Ingresso cortesia não pode ser transferido",
        };
      }

      if (this.ticket.TicketEntry.length > 0) {
        return {
          icon: "mdi-account-check",
          text: "Ingresso já foi utilizado, não pode ser transferido",
        };
      }


      if (
        moment(this.ticket.TicketBlock.TicketGroup.Party.date).isBefore(
          moment()
        )
      )
        return {
          icon: "mdi-ticket",
          text: "O evento já ocorreu, ingresso não pode ser transferido",
        };

      const [_, hoursBefore, transferBefore] =
        this.ticket.TicketBlock.TicketGroup.Party.refundPolicy.split("-");
      const partyDate = moment(this.ticket.TicketBlock.TicketGroup.Party.date);
      const now = moment();
      if (partyDate.diff(now, "hours") < (transferBefore || hoursBefore))
        return {
          icon: "mdi-clock-alert",
          text: `O ingresso não pode ser transferido pois a política do evento não permite transferências ${
            transferBefore || hoursBefore
          } horas antes do início do evento`,
        };

      return false;
    },
  },
  watch: {
    whatsapp() {
      this.error = false;
    },
  },
  mounted() {
    this.$parent.$on("transfer", this.open);
  },
};
</script>

<style></style>
