<template>
  <v-timeline-item
    :color="attraction.color"
    :icon="categories[attraction.category].icon"
    fill-dot
  >
    <v-img
      v-if="attraction.reveled && attraction.image"
      :src="attraction.image"
      class="rounded"
      max-height="200"
      contain
    />
    <!-- This div is only to remove arrow from vuetify -->
    <div>
      <v-card outlined>
        <v-card-text v-if="attraction.reveled">
          <div class="d-flex justify-space-between align-center gap-2">
            <span
              v-if="attraction.category != 'OTHER'"
              style="line-height: 1rem"
              class="text-overline flex-grow-1"
            >
              {{ categories[attraction.category].text }}
            </span>

            <span
              v-if="attraction.timed"
              class="flex-grow-0 flex-shrink-1 text-right"
            >
              {{ attraction | startEndDate }}
            </span>
          </div>
          <h4
            :class="`lh-3 text-20 font-weight-light mb-0 ${attraction.color}--text`"
          >
            {{ attraction.name }}
          </h4>
          <description-wrap :lines="2" :description="attraction.description" />

          <spotify-embed
            class="mt-3 d-flex flex-column"
            v-if="attraction.spotifyId"
            :id="attraction.spotifyId"
          />
        </v-card-text>
        <v-card-text v-else>
          <h2
            :class="`headline font-weight-light mb-0 ${attraction.color}--text`"
          >
            {{ categories[attraction.category].text }}
          </h2>
          <v-chip
            :color="attraction.color"
            :text-color="attraction.color + '--text'"
            small
            label
            style="width: 100%"
            class="justify-center white--text"
          >
            Não divulgado
          </v-chip>
        </v-card-text>
      </v-card>
    </div>
  </v-timeline-item>
</template>

<script>
import moment from "moment";
import DescriptionWrap from "../DescriptionWrap.vue";
import AttractionCategories from "@/definitions/AttractionCategories.json";
import SpotifyEmbed from "../../../components/global/SpotifyEmbed.vue";

export default {
  data: () => ({
    categories: AttractionCategories,
  }),
  components: { DescriptionWrap, SpotifyEmbed },
  props: {
    attraction: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
