<template>
  <v-card
    :elevation="elevation"
    :outlined="outlined"
    class="rounded-lg"
    :to="path.to"
    :href="path.href"
  >
    <div style="position: relative">
      <v-img
        :src="party.cover"
        class="rounded-lg rounded-b-0"
        style="cursor: pointer"
        :aspect-ratio="16 / 9"
        :style="{
          filter: !active && timeStatus ? `grayscale(80%)` : undefined,
        }"
      />
      <div
        style="position: absolute; top: 10px; left: 10px; bottom: 10px"
        class="d-flex flex-column align-left"
        v-if="timeStatus"
      >
        <div v-if="!active">
          <v-chip color="secondary" x-small>
            <v-icon x-small left>mdi-clock-outline </v-icon>
            Encerrado
          </v-chip>
        </div>
        <div v-if="party.private">
          <v-chip color="info" x-small>
            <v-icon x-small left>mdi-account-lock</v-icon>
            Privado
          </v-chip>
        </div>
      </div>
      <div
        v-if="party?.Organization?.Platform"
        style="position: absolute; top: 10px; right: 10px; bottom: 10px"
        class="d-flex flex-column align-left"
      >
        <v-chip :color="party.Organization.Platform.colors.appBar" small>
          <div>
            <v-img
              v-if="party.Organization.Platform.logos?.light"
              :src="party.Organization.Platform.logos.light"
              height="20px"
              max-width="54px"
              contain
            />
          </div>
        </v-chip>
      </div>
    </div>

    <v-card-title class="font-weight-bold">
      {{ party.name }}
      <v-spacer />
      <p class="text-body-2 mb-1 font-weight-medium" v-if="party.Address">
        <v-icon small left>mdi-map-marker</v-icon>{{ party.Address.city }} -
        {{ party.Address.state }}
      </p>
    </v-card-title>
    <v-card-subtitle class="d-flex gap-x-1 justify-space-between align-center">
      <span>
        {{
          party.date | date(active ? "DD [de] MMMM" : "DD [de] MMMM [de] YYYY")
        }}
      </span>
      <span @click.stop="goToOrg" style="cursor: pointer" class="text-end">
        {{ party.Organization.name }}
      </span>
    </v-card-subtitle>
  </v-card>
</template>

<script>
import PartyStatus from "../../utils/partyStatus";
export default {
  methods: {
    goToOrg() {
      this.$router.push({
        name: "shop.organization",
        params: {
          orgSlug: this.party.Organization.slug,
        },
      });
    },
    goToParty() {
      this.$router.push({
        name: "shop.party",
        params: {
          orgSlug: this.party.Organization.slug,
          partyId: this.party.slug || this.party.id,
        },
      });
    },
  },
  computed: {
    path() {
      const path = `/shop/${this.party.Organization.slug}/${
        this.party.slug || this.party.id
      }`;

      if (this.party?.Organization?.Platform)
        return { href: `//${this.party.Organization.Platform.domain}${path}` };
      return { to: path };
    },
    active() {
      const status = new PartyStatus(this.party);
      return status.isActive;
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
    elevation: {
      type: [String, Number],
      default: 0,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    timeStatus:{
      type: Boolean,
      default: true,
    }
  },
};
</script>

<style></style>
