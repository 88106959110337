<template>
  <v-dialog
    v-model="dialog"
    max-width=" 420px"
    :persistent="!error"
    :overlay-opacity="0.5"
    content-class="rounded-lg"
  >
    <v-card :loading="loading" rounded="lg">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <div v-if="error" class="pa-4">
        <v-alert type="error" class="mb-0">{{ error }}</v-alert>
      </div>
      <component
        v-if="payment && !error"
        :is="paymentMethods[payment?.paymentMethod].component"
        :integrationData="integrationData"
        :ticketId="ticketId"
        :payment="payment"
        :tickets="tickets"
        @update="getPayment"
        @refresh="$emit('refresh')"
        @close="close"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import TICKET from "@/services/app/ticket";
import MercadoPagoPayment from "../../../components/app/modals/payment/MercadoPagoPayment.vue";
import PagseguroPayment from "../../../components/app/modals/payment/PagseguroPayment.vue";

export default {
  components: { MercadoPagoPayment, PagseguroPayment },
  data: () => ({
    dialog: false,
    loading: false,
    error: false,
    payment: null,
    tickets: [],
    progressBar: {
      initial: 0,
      time: 0,
      interval: null,
    },
    paymentMethods: {
      MERCADO_PAGO: {
        component: "MercadoPagoPayment",
      },
      PAGSEGURO: {
        component: "PagseguroPayment",
      },
    },
  }),
  methods: {
    close() {
      this.dialog = false;
      this.payment = null;
      this.error = false;
    },
    async open() {
      this.dialog = true;
      this.error = false;
      try {
        this.loading = true;
        await this.getPayment();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
        this.error = error.message;
      }
    },

    async getPayment() {
      const { payment, tickets, data } = await TICKET.getPayment(this.ticketId);
      this.payment = payment;
      this.tickets = tickets;
      this.integrationData = data;
    },
  },
  async mounted() {
    this.$parent.$on("pay", this.open);
  },

  props: {
    ticketId: {
      type: String,
      required: true,
    },
  },
};
</script>
