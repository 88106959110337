<template>
  <div>
    <!-- {{ payment }} -->
    <v-alert
      dense
      :color="status[payment.status].color"
      text
      @click="$emit('click', payment)"
    >
      <div
        class="d-flex w-full justify-space-between align-center"
        :class="{
          'text-10': $vuetify.breakpoint.xs,
          'text-12': $vuetify.breakpoint.sm,
          'text-14': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-chip
          label
          :x-small="$vuetify.breakpoint.xs"
          :small="$vuetify.breakpoint.smAndUp"
          outlined
          :color="status[payment.status].color"
        >
          {{ status[payment.status].text }}
        </v-chip>
        <div label small v-if="alreadyPayed" class="text-center d-flex align-center">
          <v-icon x-small left :color="status[payment.status].color">
            {{ types[payment.paymentType].icon }}
          </v-icon>
          {{ types[payment.paymentType].text }}
        </div>
        <b>
          <v-btn small depressed> Ver Detalhes </v-btn>
          <!-- {{ payment.amount | currency }} -->
        </b>
      </div>
    </v-alert>
  </div>
</template>

<script>
export default {
  data: () => ({
    types: {
      UNKNOWN: {
        text: "Desconhecido",
        icon: "mdi-help-circle",
      },
      DEBIT_CARD: {
        text: "Cartão de Débito",
        icon: "mdi-credit-card",
      },
      CREDIT_CARD: {
        text: "Cartão de Crédito",
        icon: "mdi-credit-card",
      },
      PIX: {
        text: "PIX",
        icon: "mdi-rhombus-split",
      },
      PAYPAL: {
        text: "PayPal",
        icon: "mdi-contactless-payment-circle",
      },
      BANK_TRANSFER: {
        text: "Transferência Bancária",
        icon: "mdi-bank-transfer",
      },
      COURTESY: {
        text: "Cortesia",
        icon: "mdi-account-star",
      },
      MONEY: {
        text: "Dinheiro",
        icon: "mdi-cash",
      },
    },
    status: {
      succeeded: {
        text: "Aprovado",
        color: "success",
      },
      canceled: {
        text: "Cancelado",
        color: "info",
      },
      refunded: {
        text: "Reembolsado",
        color: "info",
      },
      disputed: {
        text: "Disputa",
        color: "error",
      },
      rejected: {
        text: "Rejeitado",
        color: "error",
      },
      pending: {
        text: "Pendente",
        color: "warning",
      },
      requires_payment_method: {
        text: "Aguardando Pagamento",
        color: "warning",
      },
      requires_confirmation: {
        text: "Aguardando Confirmação",
        color: "warning",
      },
      requires_action: {
        text: "Aguardando Ação",
        color: "warning",
      },
      processing: {
        text: "Processando",
        color: "warning",
      },
      requires_capture: {
        text: "Aprovado (Não Capturado)",
        color: "success",
      },
    },
  }),
  computed: {
    // Foi pago alguma vez?
    alreadyPayed() {
      return !!this.payment.payedAt;
    },
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
