const status_db_groups = {
  approved: ["succeeded"],
  canceled: ["canceled", "refunded", "disputed"],
  pending: [
    "pending",
    "requires_payment_method",
    "requires_confirmation",
    "requires_action",
    "processing",
    "requires_capture",
    "rejected",
  ],
  pending_action: ["requires_action"],
};
export default class PartyStatus {
  constructor(payment) {
    this.payment = payment;
    return this.status;
  }

  get status() {
    const { status } = this.payment;
    const [group] = Object.entries(status_db_groups).find(([group, statuses]) =>
      statuses.includes(status)
    );
    return group;
  }
}
