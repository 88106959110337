<template>
  <v-dialog
    v-model="dialog"
    width="305px"
    :overlay-opacity="0.7"
    content-class="rounded-lg"
  >
    <v-card
      v-if="
        !!filiation && !!user && user.id === filiation.userId && !user.photo
      "
      @click="importWhastappPhoto"
    >
      <v-alert
        class="mb-1"
        color="success"
        dense
        style="cursor: pointer"
        border="bottom"
        dismissible
      >
        <!-- Importar foto whatsapp -->
        <h6 class="mb-0">Importe sua foto do WhatsApp</h6>
        <span>Toque para importar sua foto.</span>
        <template v-slot:close="{ toggle }">
          <v-icon @click.stop="toggle">mdi-close-circle</v-icon>
        </template>
      </v-alert>
    </v-card>
    <membership-card :value="filiation" />
    <import-whatsapp-photo ref="importWhatsappPhoto" />
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import MembershipCard from "@/components/global/MembershipCard.vue";
import ImportWhatsappPhoto from "./account/ImportWhatsappPhoto.vue";

export default {
  components: { MembershipCard, ImportWhatsappPhoto },
  data() {
    return {
      dialog: false,
      filiation: false,
    };
  },
  methods: {
    open(filiation) {
      this.filiation = filiation;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    importWhastappPhoto() {
      this.$refs.importWhatsappPhoto.open();
      this.$emit("import-whatsapp-photo");
    },
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },
  filters: {},
};
</script>

<style></style>
