var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-center",staticStyle:{"position":"relative"}},[_c('div',[_c('v-card',{staticClass:"white--text",style:({
        borderRadius: '10px',
        border: `3px solid ${_vm.value.Membership.backgroundColor || '#000'}`,
        boxShadow: `0 0 10px  ${
          _vm.value.Membership.backgroundColor || 'rgba(0,0,0,.2)'
        }`,
        background: `url('${_vm.value.Membership.backgroundImg}'), ${
          _vm.value.Membership.backgroundColor || '#000'
        }`,
        backgroundSize: 'cover',
      })},[_c('v-responsive',{staticStyle:{"background-color":"rgba(0, 0, 0, 0.5)"},attrs:{"aspect-ratio":5.4 / 8.5,"width":"300px"}},[_c('div',{staticClass:"d-flex flex-column justify-space-between h-full pa-4"},[_c('div',{staticClass:"d-flex gap-4"},[_c('div',[_c('base-avatar',{attrs:{"size":50,"seed":_vm.value.User.id,"src":_vm.value.User.photo}})],1),_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('h6',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.value.User?.name || "Conta não criada")+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.value.User?.document))])])]),_c('div',{staticClass:"d-flex justify-center"},[_c('qrcode-vue',{staticClass:"pa-2 pb-1 white rounded",attrs:{"value":_vm.url,"size":"175","level":"M"}})],1),_c('div',{staticClass:"d-flex gap-2"},[(_vm.status == 'active')?_c('v-chip',{staticClass:"flex-shrink-0",staticStyle:{"height":"48px"},attrs:{"label":"","small":"","color":"success"}},[_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" Válido até "),_c('b',[_vm._v(" "+_vm._s(_vm._f("date")((_vm.value.MembershipPurchase.validUntil || _vm.value.MembershipPurchase[0].validUntil)))+" ")])])]):(_vm.status == 'expired')?_c('v-chip',{staticClass:"flex-shrink-0",staticStyle:{"height":"48px"},attrs:{"label":"","small":"","color":"error"}},[_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" Vencida em "),_c('b',[_vm._v(" "+_vm._s(_vm._f("date")((_vm.value.MembershipPurchase.validUntil || _vm.value.MembershipPurchase[0].validUntil)))+" ")])])]):_vm._e(),_c('div',{staticStyle:{"display":"grid"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.value.Membership.Organization.name)+" ")]),_c('h6',{staticClass:"white--text text-truncate mb-0"},[_vm._v(" "+_vm._s(_vm.value.Membership.name)+" ")])])],1)])])],1)],1),_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"0px","width":"100%","height":"100%"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }