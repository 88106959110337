<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="`Ingressos ${party.name}`"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width=""
      @progress="onProgress($event)"
      @hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf"
    >
      <section class="v-application" slot="pdf-content">
        <div>
          <v-row no-gutters class="ma-4 mt-0">
            <v-col
              style="height: 14.44cm"
              v-for="ticket in tickets"
              :key="ticket.id"
              class="mt-4"
              cols="6"
            >
              <v-card
                color="#f3f3f6"
                style="height: 14cm"
                class="w-100 mx-1 rounded-t-xl"
              >
                <export-ticket :ticket="ticket" :user="user" :images="images" />
                <ticket-dots bottom color="#fff" />
              </v-card>
            </v-col>
          </v-row>

          <party-details
            :party="party"
            :images="images"
            :fullAddress="fullAddress"
            :geometry="geometry"
          />
          <div class="d-flex w-full justify-center">
            <p class="text-12">
              Arquivo gerado em {{ new Date().toLocaleString() }}
            </p>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import ExportTicket from "@/components/app/myTickets/ExportTicket.vue";
import PartyDetails from "@/components/app/myTickets/ExportPartyDetails.vue";
import TicketDots from "../../../components/global/TicketDots.vue";
const { VUE_APP_MAPS_KEY } = process.env;

import VueHtml2pdf from "vue-html2pdf";
import { mapGetters } from "vuex";
export default {
  components: { ExportTicket, VueHtml2pdf, PartyDetails, TicketDots },
  data: () => ({
    dialog: false,
    images: {
      cover: null,
      croppedCover: null,
      favicon: null,
      map: null,
    },
  }),
  methods: {
    async blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result;
          resolve(dataUrl);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(blob);
      });
    },
    mapUrl() {
      if (!!this.party?.Address) {
        const point = this.geometry
          ? `${this.geometry.lat},${this.geometry.lng}`
          : this.fullAddress;

        const url = new URL(`https://maps.googleapis.com/maps/api/staticmap`);
        url.searchParams.set("center", point);
        url.searchParams.set("zoom", this.party?.locationReveled ? "14" : "11");
        url.searchParams.set("size", "300x300");
        url.searchParams.set("key", VUE_APP_MAPS_KEY);
        if (this.party?.locationReveled)
          url.searchParams.set("markers", `color:blue|${point}`);

        return `${url.toString()}`;
      } else
        return "https://th.bing.com/th/id/R.5340efb1b2c7fdeec32fcb0670061d0b?rik=Y0C2wya1vF6v2Q&riu=http%3a%2f%2fwww.bartoni.it%2fwritable%2fmod_sezioni%2f20190516180504-2019-50983-NDP.jpg&ehk=sGDNrNyQr%2fI5we2PntlVcst6pdprdmK6AOjrB%2bb5QUM%3d&risl=&pid=ImgRaw&r=0";
    },
    async downloadImageAsBase64(url) {
      try {
        const blob = await this.downloadImage(url);
        return await this.blobToBase64(blob);
      } catch (error) {
        console.error("Erro ao baixar a imagem:", error);
        throw error;
      }
    },
    async downloadImage(url) {
      const response = await fetch(url);
      return await response.blob();
    },
    async recortImg(url, width, height) {
      const img = new Image();

      img.crossOrigin = "anonymous";

      const loadImage = new Promise((resolve, reject) => {
        img.onload = () => resolve();
        img.onerror = () => reject(new Error("Falha ao carregar a imagem"));
        img.src = url;
      });

      await loadImage;

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = width;
      canvas.height = height;

      const ratio = Math.min(img.width / width, img.height / height);
      const centerWidth = img.width / ratio;
      const centerHeight = img.height / ratio;
      const left = (centerWidth - width) / 2;
      const top = (centerHeight - height) / 2;

      ctx.drawImage(
        img,
        left * ratio,
        top * ratio,
        width * ratio,
        height * ratio,
        0,
        0,
        width,
        height
      );

      return canvas.toDataURL("image/png");
    },
    async downloadPDF() {
      const promise = new Promise((resolve, reject) => {
        this.promise = { resolve, reject };
      });

      const coverBase64 = await this.downloadImageAsBase64(this.party.cover);
      const favicon = await this.downloadImageAsBase64("/favicon.png");
      this.images.favicon = favicon;
      this.images.cover = coverBase64;
      this.images.map = await this.downloadImageAsBase64(this.mapUrl());
      this.images.croppedCover = await this.recortImg(coverBase64, 361, 150);
      this.$refs.html2Pdf.generatePdf();

      return promise;
    },
    onProgress(progress) {
      console.log("PDF Progress: ", progress);
    },
    hasDownloaded(pdf) {
      this.promise.resolve(pdf);
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),

    geometry() {
      if (!this.party?.Address?.lat || !this.party?.Address?.lng) return null;
      return {
        lat: this.party.Address.lat,
        lng: this.party.Address.lng,
      };
    },
    fullAddress() {
      if (!this.party?.Address) return "";
      const addrs = this.party.Address;
      var address = "";
      if (this.party.locationReveled) {
        if (addrs.street) {
          address += `${addrs.street}`;
          if (addrs.number) address += `, ${addrs.number}`;
        }
        if (addrs.neighborhood) address += ` - ${addrs.neighborhood}`;
        if (addrs.complement) address += ` - ${addrs.complement}`;
        if (address) address += ` - `;
        address += `${addrs.city} - ${addrs.state}`;
        if (addrs.zipCode) address += ` - ${addrs.zipCode}`;
      } else {
        address = `${addrs.city} - ${addrs.state}`;
      }

      return address;
    },
    obfuscatedCPF() {
      return this.user.document
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.***.***-$4");
    },
  },
  props: {
    party: {
      type: Object,
      default: () => null,
    },
    tickets: {
      type: Array,
      default: () => [],
    },
  },

  mounted() {
    this.$parent.$on("openExport", this.open);
  },
};
</script>

<style></style>
