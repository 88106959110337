<template>
  <v-card outlined :to="to.to" :href="to.href">
    <v-card-text
      class="white--text d-flex flex-column gap-8"
      :style="{
        background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${filiation.Membership.backgroundImg})`,
        backgroundSize: 'cover',
        border: `1px solid ${filiation.Membership.backgroundColor || '#000'}`,
        boxShadow: `0 0 10px  ${
          filiation.Membership.backgroundColor || 'rgba(0,0,0,.2)'
        }`,
      }"
    >
      <div class="d-flex align-start gap-4 justify-space-between">
        <div>
          <h4 class="white--text mb-0">{{ filiation.Membership.name }}</h4>
          <p class="mb-0">{{ filiation.Membership.Organization.name }}</p>
        </div>
        <v-btn v-if="!platform" color="white" icon :to="to.to">
          <v-icon color="white">mdi-dots-vertical</v-icon>
        </v-btn>
        <v-chip v-else :color="platform.colors.appBar" small>
          <div>
            <v-img
              v-if="platform.logos?.light"
              :src="platform.logos.light"
              height="20px"
              max-width="54px"
              contain
            />
          </div>
        </v-chip>
      </div>
      <div class="d-flex align-end gap-4 justify-space-between">
        <v-chip
          label
          :color="chipStatus[lastPurchase.status].color"
          style="height: 48px"
        >
          <b v-if="lastPurchase.status == 'active'">
            Válido até <br />
            {{ lastPurchase.validUntil | date }}
          </b>
          <b v-else-if="lastPurchase.status == 'expired'">
            Vencido em <br />
            {{ lastPurchase.validUntil | date }}
          </b>
          <template v-else>{{ chipStatus[lastPurchase.status].text }}</template>
        </v-chip>
        <v-btn
          v-if="lastPurchase.status == 'active'"
          @click="qrCodeModal"
          class="black--text"
          color="white"
          small
        >
          <v-icon left small color="black">mdi-qrcode</v-icon>
          Ver cartão
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import pStatus from "@/utils/paymentStatus";
import { mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Minhas filiações",
  },
  data: () => ({
    hidePurchase: true,
    chipStatus: {
      active: {
        color: "success",
        text: "Ativo",
      },
      pending: {
        color: "info",
        text: "Pendente",
      },
      inactive: {
        color: "secondary",
        text: "Inativo",
      },
      expired: {
        color: "error",
        text: "Expirado",
      },
    },
  }),
  methods: {
    details() {
      this.$router.push({
        name: "app.membership.details",
        params: {
          id: this.filiation.id,
        },
      });
    },
    qrCodeModal() {
      this.$emit("view", { ...this.filiation, User: this.user });
    },
    status(purchase) {
      if (!purchase) return "inactive";
      const paymentStatus = new pStatus(purchase.Payment);
      if (
        paymentStatus.status === "pending" ||
        paymentStatus.status === "pending_action"
      )
        return "pending";
      if (paymentStatus.status === "canceled") return "inactive";

      if (moment(purchase.validFrom).isAfter(moment())) return "pending";
      if (moment(purchase.validUntil).isBefore(moment())) return "expired";
      return "active";
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    to() {
      if (this.platform) {
        const url = `https://${this.platform.domain}/app/membership/${this.filiation.id}`;
        return { href: url };
      }

      return {
        to: {
          name: "app.membership.details",
          params: { id: this.filiation.id },
        },
      };
    },
    platform() {
      return this.filiation?.Membership?.Organization?.Platform;
    },
    lastPurchase() {
      const purchases = this.filiation.MembershipPurchase.sort((a, b) => {
        return new Date(b.validUntil) - new Date(a.validUntil);
      });
      const [last] = purchases;
      const status = this.status(last);
      return {
        ...last,
        status,
      };
    },
  },
  props: {
    filiation: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
