<template>
  <div class="d-flex justify-center" style="position: relative">
    <div>
      <v-card
        class="white--text"
        :style="{
          borderRadius: '10px',
          border: `3px solid ${value.Membership.backgroundColor || '#000'}`,
          boxShadow: `0 0 10px  ${
            value.Membership.backgroundColor || 'rgba(0,0,0,.2)'
          }`,
          background: `url('${value.Membership.backgroundImg}'), ${
            value.Membership.backgroundColor || '#000'
          }`,
          backgroundSize: 'cover',
        }"
      >
        <v-responsive
          :aspect-ratio="5.4 / 8.5"
          width="300px"
          style="background-color: rgba(0, 0, 0, 0.5)"
        >
          <div class="d-flex flex-column justify-space-between h-full pa-4">
            <div class="d-flex gap-4">
              <div>
                <base-avatar
                  :size="50"
                  :seed="value.User.id"
                  :src="value.User.photo"
                />
              </div>
              <div class="d-flex flex-column justify-center">
                <h6 class="mb-0">
                  {{ value.User?.name || "Conta não criada" }}
                </h6>
                <small class="text-muted">{{ value.User?.document }}</small>
              </div>
            </div>
            <div class="d-flex justify-center">
              <qrcode-vue
                class="pa-2 pb-1 white rounded"
                :value="url"
                size="175"
                level="M"
              />
            </div>

            <div class="d-flex gap-2">
              <v-chip
                v-if="status == 'active'"
                label
                small
                color="success"
                class="flex-shrink-0"
                style="height: 48px"
              >
                <div class="d-flex flex-column">
                  Válido até
                  <b>
                    {{
                      (value.MembershipPurchase.validUntil ||
                        value.MembershipPurchase[0].validUntil) | date
                    }}
                  </b>
                </div>
              </v-chip>
              <v-chip
                v-else-if="status == 'expired'"
                label
                small
                color="error"
                class="flex-shrink-0"
                style="height: 48px"
              >
                <div class="d-flex flex-column">
                  Vencida em
                  <b>
                    {{
                      (value.MembershipPurchase.validUntil ||
                        value.MembershipPurchase[0].validUntil) | date
                    }}
                  </b>
                </div>
              </v-chip>
              <div style="display: grid">
                <p class="mb-0">
                  {{ value.Membership.Organization.name }}
                </p>
                <h6 class="white--text text-truncate mb-0">
                  {{ value.Membership.name }}
                </h6>
              </div>
            </div>
          </div>
        </v-responsive>
      </v-card>
    </div>
    <div
      style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%"
    />
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import filiationStatus from "@/utils/filiationStatus";

export default {
  components: { QrcodeVue },
  data: () => ({
    status: "",
  }),
  methods: {
    calculateStatus() {
      const status = new filiationStatus(this.value);
      this.status = status.status;
    },
  },
  computed: {
    url() {
      return `${window.location.origin}/verify/membership/${this.value.id}`;
    },
  },
  mounted() {
    this.calculateStatus();
  },
  watch: {
    value() {
      this.calculateStatus();
    },
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.fixed {
  position: fixed !important;
}

.top-0 {
  top: 0 !important;
}
.bottom-0 {
  bottom: 0 !important;
}
</style>
