<template>
  <div>
    <div :style="{ 'max-height': maxHeight, overflow: 'hidden' }">
      <base-md-viewer
        :value="description || ''"
        :initialized="initialized"
        :holder="holderValue"
      >
      </base-md-viewer>
    </div>
    <div
      class="d-flex flex-column justify-center"
      v-if="contentHeight > linesHeight"
    >
      <v-divider style="margin-bottom: -11px" class="mt-2" v-if="wrap" />
      <v-btn text x-small @click="wrap = !wrap" class="text--disabled">
        {{ !wrap ? "Ver menos" : "Ver mais" }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    wrap: true,
    contentHeight: 0,
  }),
  methods: {
    initialized(editor) {
      this.contentHeight = editor.options.el.offsetHeight;
    },
  },
  computed: {
    maxHeight() {
      return this.wrap ? `${this.linesHeight}px` : undefined;
    },
    linesHeight() {
      return 10 + 21 * this.lines;
    },
    holderValue() {
      return this.holder || "dw-" + Math.random().toString(36).substring(7);
    },
  },
  props: {
    description: {
      type: String | null,
      required: true,
    },
    lines: {
      type: Number,
      default: 3,
    },
    holder: {
      type: String,
    },
  },
};
</script>

<style></style>
