var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"outlined":"","to":_vm.to.to,"href":_vm.to.href}},[_c('v-card-text',{staticClass:"white--text d-flex flex-column gap-8",style:({
      background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${_vm.filiation.Membership.backgroundImg})`,
      backgroundSize: 'cover',
      border: `1px solid ${_vm.filiation.Membership.backgroundColor || '#000'}`,
      boxShadow: `0 0 10px  ${
        _vm.filiation.Membership.backgroundColor || 'rgba(0,0,0,.2)'
      }`,
    })},[_c('div',{staticClass:"d-flex align-start gap-4 justify-space-between"},[_c('div',[_c('h4',{staticClass:"white--text mb-0"},[_vm._v(_vm._s(_vm.filiation.Membership.name))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.filiation.Membership.Organization.name))])]),(!_vm.platform)?_c('v-btn',{attrs:{"color":"white","icon":"","to":_vm.to.to}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-dots-vertical")])],1):_c('v-chip',{attrs:{"color":_vm.platform.colors.appBar,"small":""}},[_c('div',[(_vm.platform.logos?.light)?_c('v-img',{attrs:{"src":_vm.platform.logos.light,"height":"20px","max-width":"54px","contain":""}}):_vm._e()],1)])],1),_c('div',{staticClass:"d-flex align-end gap-4 justify-space-between"},[_c('v-chip',{staticStyle:{"height":"48px"},attrs:{"label":"","color":_vm.chipStatus[_vm.lastPurchase.status].color}},[(_vm.lastPurchase.status == 'active')?_c('b',[_vm._v(" Válido até "),_c('br'),_vm._v(" "+_vm._s(_vm._f("date")(_vm.lastPurchase.validUntil))+" ")]):(_vm.lastPurchase.status == 'expired')?_c('b',[_vm._v(" Vencido em "),_c('br'),_vm._v(" "+_vm._s(_vm._f("date")(_vm.lastPurchase.validUntil))+" ")]):[_vm._v(_vm._s(_vm.chipStatus[_vm.lastPurchase.status].text))]],2),(_vm.lastPurchase.status == 'active')?_c('v-btn',{staticClass:"black--text",attrs:{"color":"white","small":""},on:{"click":_vm.qrCodeModal}},[_c('v-icon',{attrs:{"left":"","small":"","color":"black"}},[_vm._v("mdi-qrcode")]),_vm._v(" Ver cartão ")],1):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }