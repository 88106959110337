<template>
  <div>
    <v-progress-linear
      :value="(progressBar.time / progressBar.initial) * 100"
    />
    <v-card-text class="pt-6">
      <div v-if="payment">
        <!-- Text redirect to payment -->
        <v-img
          src="@/assets/images/brands/mercadopago.svg"
          class="mb-6"
          height="100"
          contain
        />
        <p class="text--primary text-center mb-0 mt-4">
          Conclua seu pagamento com segurança via Mercado Pago.
        </p>
        <p class="text--primary text-center">
          Após o pagamento, você será redirecionado para o seu ingresso.
        </p>
        <!-- progressbar -->
      </div>
      <v-alert
        v-if="error"
        border="left"
        class="text-left mt-2 mb-2"
        text
        icon="mdi-alert-circle-outline"
        type="error"
      >
        {{ error }}
      </v-alert>
    </v-card-text>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    loading: false,
    error: false,
    payment: null,
    progressBar: {
      initial: 0,
      time: 0,
      interval: null,
    },
  }),
  methods: {
    startProgressBar(ms) {
      const resolution = 50;
      return new Promise((resolve) => {
        this.progressBar.time = this.progressBar.initial = ms;
        this.progressBar.interval = setInterval(() => {
          if (this.progressBar.time > 0) {
            this.progressBar.time -= resolution;
          } else {
            clearInterval(this.progressBar.interval);
            resolve();
          }
        }, resolution);
      });
    },
  },
  async mounted() {
    await this.startProgressBar(5000);
    window.location.href = secrets.checkoutUrl;
  },
  beforeDestroy() {
    this.progressBar.time = 0;
    clearInterval(this.progressBar.interval);
  },
  props: {
    ticketId: {
      type: String,
      required: true,
    },
    payment: {
      type: Object,
      required: true,
    },
    tickets: {
      type: Array,
      required: true,
    },
  },
};
</script>

</script>