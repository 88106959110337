import http from "@/http-common";

const getById = async (id) => {
  return await http
    .get(`/app/payment/${id}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const cancelById = async (id) => {
  return await http
    .delete(`/app/payment/${id}`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  getById,
  cancelById,
};
