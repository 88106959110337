<template>
  <div class="w-full">
    <v-card outlined class="ma-6 pa-5 py-4 rounded-lg">
      <!-- <img
          style="width: 300px; height: 165px; object-fit: cover"
          class="rounded-l-lg rounded-r-0"
          :src="images.cover"
          :aspect-ratio="16 / 9"
        /> -->
      <div class="d-flex">
        <div class="d-flex flex-grow-1 flex-column">
          <div class="d-flex justify-space-between">
            <span class="font-weight-bold text-18">{{ party.name }}</span>
            <v-chip
              label
              small
              :color="party.ageGroup == 0 ? 'success' : 'info'"
              class="font-weight-medium mx-3"
            >
              <span style="color: white">
                {{ party.ageGroup == 0 ? "Livre" : `+${party.ageGroup}` }}
                {{ party.ageWithParent ? "*" : "" }}
              </span>
            </v-chip>
          </div>
          <div class="d-flex flex-column gap-1">
            <div class="d-flex align-center">
              <span style="font-size: 9px !important" class="text-overline lh-1"
                >{{ party.date | formatDate }} até
                {{ party.endDate | formatDate }}</span
              >
            </div>
          </div>
          <div class="mb-0">
            <p class="text-subtitle-2 mb-0 lh-1 font-weight-bold">
              Faixa Etária
            </p>
            <p class="mb-0 text--secondary">
              <age-group-text :party="party" />
            </p>
          </div>
          <div class="pt-0" v-if="party?.requireDocuments">
            <p class="text-subtitle-2 lh-1 mb-0 font-weight-bold">
              Documentos necessários
            </p>
            <p class="mb-0 text--secondary">
              {{ party.requireDocuments }}
            </p>
          </div>

          <div class="pt-0" v-if="party?.dressCode">
            <p class="text-subtitle-2 lh-1 mb-0 font-weight-bold">
              Normas de vestimenta
            </p>
            <p class="mb-0 text--secondary">
              {{ party.dressCode }}
            </p>
          </div>
        </div>
        <div class="align-self-end">
          <qrcode-vue
            style="
              background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
              background-size: cover;
            "
            class="d-flex justify-center white rounded"
            :value="partyLink"
            size="100"
            level="M"
          />
          <small class="text-center d-block">Mais Detalhes</small>
        </div>
      </div>
    </v-card>
    <v-card
      v-if="party?.Address && party.locationReveled"
      outlined
      class="d-flex pa-2 rounded-lg align-center gap-4 ma-6"
    >
      <img
        style="width: 150px; height: 150px; object-fit: cover"
        class="rounded-lg"
        :src="images.map"
        :aspect-ratio="16 / 9"
      />
      <div class="flex-grow-1">
        <span class="font-weight-bold text-18">{{
          this.party?.Address.name
        }}</span>
        <p>{{ this.fullAddress }}</p>
      </div>
      <div>
        <qrcode-vue
          style="
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
            background-size: cover;
          "
          class="d-flex justify-center white rounded"
          :value="mapsLink"
          size="130"
          level="M"
        />
        <small class="text-10 text-center d-block"> Rota para o evento </small>
      </div>
    </v-card>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import AgeGroupText from "../../global/party/AgeGroupText.vue";
import moment from "moment";
export default {
  components: {
    QrcodeVue,
    AgeGroupText,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    mapsLink() {
      let address = this.geometry
        ? `${this.geometry.lat},${this.geometry.lng}`
        : this.fullAddress;
      let url = `https://www.google.com/maps/dir/?api=1&parameters&destination=${address}`;
      return new URL(url).href;
    },
    partyLink() {
      return `${window.location.origin}/shop/${this.party.Organization.slug}/${
        this.party.slug || this.party.id
      }`;
    },
  },

  filters: {
    formatDate(date) {
      const momentDate = moment(date);
      var formatPattern = "DD [de] MMM";

      if (momentDate.year() != moment().year()) formatPattern += " [de] YYYY";
      else if (momentDate.isBefore(moment(), "day"))
        formatPattern += " [de] YYYY";

      formatPattern += " [às] HH[h]mm";

      return momentDate.format(formatPattern);
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
    images: {
      type: Object,
      required: true,
    },
    fullAddress: {
      type: String,
    },
    geometry: {
      type: Object,
    },
  },
};
</script>

<style scoped>
p {
  display: block;
}
</style>
