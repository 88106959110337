<template>
  <div class="pt-4">
    <h4 :class="{ 'white--text': $vuetify.breakpoint.smAndDown }">
      Minhas filiações
    </h4>
    <div class="px-2" v-if="loading">
      <v-progress-linear indeterminate />
    </div>

    <cols-organizer
      v-if="filiations.length"
      :items="filiations"
      :cols="1"
      :sm="2"
      :lg="3"
    >
      <template v-slot:default="{ item }">
        <my-membership :filiation="item" @view="openModal" />
      </template>
    </cols-organizer>
    <div
      v-else-if="!loading"
      style="height: 50vh"
      class="d-flex flex-column justify-center"
    >
      <div class="justify-center d-flex">
        <v-avatar size="75" color="grey lighten-3">
          <v-icon x-large color="grey darken-2">mdi-card-account-details-star</v-icon>
        </v-avatar>
      </div>
      <p class="text-center text-subtitle-1 mb-6 mt-1">
        Você não possui filiações
      </p>
    </div>

    <v-alert
      v-if="error"
      border="left"
      class="text-left"
      text
      icon="mdi-alert-circle-outline"
      type="error"
    >
      {{ error }}
    </v-alert>
    <membership-card-modal ref="membershipModal" />
  </div>
</template>

<script>
import MEMBERSHIP from "@/services/app/membership";
import ColsOrganizer from "../../../components/global/ColsOrganizer.vue";
import MyMembership from "../../../components/app/membership/MyMembership.vue";
import MembershipCardModal from "../../../components/global/MembershipCardModal.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: { ColsOrganizer, MyMembership, MembershipCardModal },
  data: () => ({
    loading: true,
    error: null,
  }),
  methods: {
    ...mapActions("app", ["updateFiliations"]),
    openModal(data) {
      this.$refs.membershipModal.open(data);
    },
    async getMemberships() {
      try {
        this.loading = true;
        this.error = null;
        await this.updateFiliations();
      } catch (error) {
        this.error = error.message || "Erro ao carregar filiações";
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapState("app", ["filiations"]),
  },
  mounted() {
    this.getMemberships();
  },
};
</script>

<style></style>
