<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    :persistent="loading"
    @click:outside="close"
    style="overflow: hidden"
  >
    <v-card v-if="!!payment" style="overflow: hidden">
      <v-card-text class="pt-4">
        <div class="d-flex flex-column align-center justify-center">
          <v-avatar size="70" color="warning">
            <v-icon x-large color="white">mdi-alert</v-icon>
          </v-avatar>
          <h4>Atenção</h4>
        </div>
        <h6 class="text-center">
          Você tem certeza que deseja cancelar o pagamento?
        </h6>

        <v-alert type="info" icon="mdi-ticket" dense>
          {{ tickets.length }} ingresso{{ tickets.length > 1 ? "s" : "" }}
          reservados para você.
        </v-alert>
        <v-alert color="warning" dense text class="mb-0">
          Ao cancelar o pagamento, você perderá a reserva dos ingressos. Será
          necessário realizar uma nova compra, é possível que os ingressos não
          estejam mais disponíveis.
        </v-alert>
        <v-alert type="error" dense v-if="error" class="mt-4">{{
          error
        }}</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="close" :disabled="loading">Fechar</v-btn>
        <v-spacer />
        <v-btn color="warning" :loading="loading" @click="cancelPayment">
          Cancelar pagamento
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PAYMENT from "@/services/app/payment";

export default {
  data: () => ({
    dialog: false,
    loading: false,
    error: null,
  }),
  methods: {
    open() {
      this.dialog = true;
      this.loading = false;
    },
    close(redirect = false) {
      if (redirect === true) {
        this.$router.push({
          name: "shop.party",
          params: {
            orgSlug: this.party.Organization.slug,
            partyId: this.party.slug || this.party.id,
          },
        });
        return;
      }
      this.dialog = false;
    },
    async cancelPayment() {
      try {
        this.loading = true;
        await PAYMENT.cancelById(this.payment.id);
        this.succeed = true;
        this.close(true);
      } catch (error) {
        this.error = error.message || error;
        this.loading = false;
      }
    },
  },
  computed: {},

  mounted() {},
  props: {
    payment: {
      type: Object,
    },
    tickets: {
      type: Array,
      default: () => [],
    },
    party: {
      type: Object,
    },
  },
};
</script>

<style></style>
