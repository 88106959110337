<template>
  <div class="d-flex" style="width: 100%">
    <div
      class="flex-column"
      v-for="(items, index) in colsItens"
      :key="index"
      :style="{ width: 100 / nCols + '%' }"
      :class="'px-' + gap"
    >
      <div
        :class="yndex != 0 ? 'mt-' + gap * 2 : ''"
        v-for="(item, yndex) in items"
        :key="itemKey ? item[itemKey] || yndex : yndex"
      >
        <slot :item="item" :index="yndex" class="mb-2" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    sizeOrder: ["xl", "lg", "md", "sm", "xs"],
  }),
  computed: {
    colsItens() {
      let nCols = this.nCols;
      let cols = [];
      for (let i = 0; i < nCols; i++) {
        cols.push([]);
      }

      this.items.forEach((item, index) => {
        cols[index % nCols].push(item);
      });
      return cols;
    },
    nCols() {
      var name = this.$vuetify.breakpoint.name;
      var size = this.sizeOrder.indexOf(name);
      for (var i = size; i < this.sizeOrder.length; i++) {
        var sizeName = this.sizeOrder[i];
        if (this[sizeName]) return parseInt(this[sizeName]);
      }

      return parseInt(this.cols || 1);
    },
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemKey: {
      type: String,
    },
    cols: {
      type: Number | String,
      default: null,
    },
    xs: {
      type: Number | String,
      default: null,
    },
    sm: {
      type: Number | String,
      default: null,
    },
    md: {
      type: Number | String,
      default: null,
    },
    lg: {
      type: Number | String,
      default: null,
    },
    xl: {
      type: Number | String,
      default: null,
    },
    gap: {
      type: Number | String,
      default: 2,
    },
  },
};
</script>

<style></style>
