import PartyStatus from "./partyStatus";
export default (a, b) => {
  const statusA = new PartyStatus(a);
  const statusB = new PartyStatus(b);
  // Se inProgress é diferente, retorne o que for inProgress
  if (statusA.isInProgress !== statusB.isInProgress) {
    return statusA.isInProgress ? -1 : 1;
  }
  // Se isActive é diferente, retorne o que for isActive
  if (statusA.isActive !== statusB.isActive) {
    return statusA.isActive ? -1 : 1;
  }
  if (statusA.isActive) return new Date(a.date) - new Date(b.date);

  return new Date(b.date) - new Date(a.date);
};
