<template>
  <div class="text-center">
    <v-avatar size="80" class="mx-auto" color="secondary" style="z-index: 2">
      <img v-if="partner.image" :src="partner.image" />
      <v-icon x-large color="white" v-else>mdi-store</v-icon>
    </v-avatar>
    <v-card outlined style="margin-top: -40px" class="rounded-xl">
      <v-card-text class="pt-12 pb-0">
        <p class="mb-0 text-overline font-weight-medium" style="line-height: 1rem;"> {{ partner.category }}</p>
        <h6 class="text--primary mb-0">{{ partner.name }}</h6>
        <div class="d-flex justify-center gap-1">
          <v-btn icon :disabled="loading" v-if="partner.phone" @click="phone">
            <v-icon small>mdi-phone</v-icon>
          </v-btn>
          <v-btn
            v-if="partner.website"
            :disabled="loading"
            @click="website"
            icon
          >
            <v-icon small>{{ websiteIcon }}</v-icon>
          </v-btn>
          <v-btn
            v-if="partner.Address"
            :disabled="loading"
            @click="address"
            icon
          >
            <v-icon small>mdi-map-marker</v-icon>
          </v-btn>
        </div>
        <description-wrap :description="partner.description" :lines="2" />
        <v-alert v-if="error" type="error" dismissible dense>
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import MEMBERSHIP from "@/services/admin/membership";

import DescriptionWrap from "../../global/DescriptionWrap.vue";
export default {
  components: { DescriptionWrap },
  data: () => ({
    loading: false,
    removing: false,
    timeout: null,
    error: null,
  }),
  methods: {
    async remove() {
      try {
        this.error = null;
        if (!this.removing) {
          this.removing = true;
          this.timeout = setTimeout(() => {
            this.removing = false;
            this.timeout = null;
          }, 4000);
        } else {
          clearTimeout(this.timeout);
          this.removing = false;
          this.loading = true;
          await MEMBERSHIP.partner.delete(
            this.partner.organizationId,
            this.partner.id
          );
          this.$emit("refresh");
        }
      } catch (e) {
        this.error = e.message || "Erro ao remover parceiro";
        this.loading = false;
      }
    },
    phone() {
      window.open("tel:" + this.partner.phone);
    },
    website() {
      window.open(this.partner.website);
    },
    fullAddress() {
      const addrs = this.partner.Address;
      if (!addrs) return;
      var address = "";
      address += `${addrs.street}`;
      if (addrs.number) address += `, ${addrs.number}`;
      address += ` - ${addrs.neighborhood}`;
      if (addrs.complement) address += ` - ${addrs.complement}`;
      address += ` - ${addrs.city} - ${addrs.state}`;
      if (addrs.zipCode) address += ` - ${addrs.zipCode}`;

      return address;
    },
    address() {
      const fullAddress = this.fullAddress();
      if (!fullAddress) return;
      let address = fullAddress;
      let url = `https://www.google.com/maps/dir/?api=1&parameters&destination=${address}`;
      window.open(encodeURI(url), "_blank").focus();
    },
  },
  computed: {
    websiteIcon() {
      if (!this.partner.website) return "mdi-web";
      const url = new URL(this.partner.website);
      if (url.hostname.includes("facebook")) return "mdi-facebook";
      if (url.hostname.includes("instagram")) return "mdi-instagram";
      if (url.hostname.includes("twitter")) return "mdi-twitter";
      if (url.hostname.includes("youtube") || url.hostname.includes("youtu"))
        return "mdi-youtube";
      if (url.hostname.includes("linkedin")) return "mdi-linkedin";
      if (url.hostname.includes("whatsapp") || url.hostname.includes("wa"))
        return "mdi-whatsapp";
      return "mdi-web";
    },
  },
  props: {
    partner: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
