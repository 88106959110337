<template>
  <div>
    <my-tickets></my-tickets>
  </div>
</template>

<script>
import MyTickets from "./ticket/MyTickets.vue";
export default {
  components: { MyTickets },
  data() {
    return {};
  },
  computed: {},
};
</script>
