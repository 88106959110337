<template>
  <v-alert
    v-if="!inPwa && !closed && canInstallPwa"
    :type="installed ? successType : type"
    :dense="dense"
    :outlined="outlined"
    :border="border"
    :icon="installed ? successIcon : icon"
  >
    <div
      v-if="!installed"
      class="d-flex justify-space-between align-center flex-wrap"
    >
      <div>
        <slot name="message" />
      </div>
      <div>
        <v-btn icon v-if="allowDeny">
          <v-icon @click="close">mdi-close</v-icon>
        </v-btn>
        <v-btn @click="install"> Instalar </v-btn>
      </div>
    </div>
    <div v-else class="d-flex justify-space-between align-center flex-wrap">
      <div>
        <slot name="success-message" />
      </div>
      <div>
        <v-btn icon>
          <v-icon @click="close">mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
  </v-alert>
</template>

<script>
export default {
  data: () => ({
    closed: false,
    installed: false,
  }),
  methods: {
    close() {
      this.closed = true;
    },
    install() {
      this.pwaDeferredPrompt.prompt();
      this.pwaDeferredPrompt.userChoice.then((choice) => {
        if (choice.outcome === "accepted") {
          this.installed = true;
        }
      });
    },
  },
  computed: {},
  props: {
    type: {
      type: String,
      default: "info",
    },
    successType: {
      type: String,
      default: "success",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    border: {
      type: String,
    },
    icon: {
      type: Boolean,
    },
    successIcon: {
      type: Boolean,
    },
    allowDeny: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
