<template>
  <v-app>
    <!-- <keep-alive> -->
    <component :is="layout" />
    <!-- </keep-alive> -->

    <!-- <customizer /> -->
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Blank: () => import("@/layouts/Blank"),
    Customizer: () => import("@/layouts/common-drawer/Customizer"),
  },
  data() {
    return {
      layoutName: "VerticalSix",
    };
  },
  computed: {
    layout() {
      return this.$route.meta.layout || this.layoutName;
    },
  },
  methods: {
    ...mapActions(["changeThemeLayout"]),
  },
};
</script>
