<template>
  <div>
    <div class="d-flex align-center gap-2 flex-wrap ">
      <v-text-field
        v-if="showSearch"
        dense
        hide-details
        label="Pesquisar"
        v-model="innerSearch"
        outlined
      />

      <v-chip-group v-model="categoryFilter" mandatory column>
        <v-chip filter outlined :value="null"> Todos </v-chip>
        <v-chip
          filter
          outlined
          v-for="[c, count] in categories"
          :key="c"
          :value="c"
        >
          {{ c }} ({{ count }})
        </v-chip>
      </v-chip-group>
    </div>

    <cols-organizer
      :items="filterPartners"
      :cols="1"
      :sm="2"
      :md="2"
      :lg="3"
      :xl="4"
    >
      <template v-slot="{ item }">
        <partner-card :partner="item" />
      </template>
    </cols-organizer>
  </div>
</template>

<script>
import PartnerCard from "./PartnerCard.vue";
import ColsOrganizer from "../../../components/global/ColsOrganizer.vue";

export default {
  components: { PartnerCard, ColsOrganizer },
  data: () => ({
    categoryFilter: null,
    innerSearch: "",
  }),
  computed: {
    categories() {
      return Object.entries(
        this.partners.reduce((acc, p) => {
          if (!acc[p.category]) acc[p.category] = 1;
          else acc[p.category]++;
          return acc;
        }, {})
      );
    },
    filterPartners() {
      return this.partners.filter((p) => {
        if (this.categoryFilter && p.category !== this.categoryFilter)
          return false;

        const search = this.search || this.innerSearch;
        if (
          search &&
          !p.name.toLowerCase().includes(search.toLowerCase())
        )
          return false;
        return true;
      });
    },
  },
  props: {
    partners: {
      type: Array,
      default: () => [],
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
