<template>
  <v-alert icon="mdi-whatsapp" type="success" border="left" text class="mb-0">
    <template v-if="s || success">
      <span>
        <b>Grupo no Whatsapp</b>
        <br />
        {{ s ? "Você foi adicionado no grupo!" : "Você já está no grupo!" }}
      </span>
    </template>
    <div v-else class="d-flex align-center justify-space-between flex-wrap">
      <span>
        <b>Esse evento possui um grupo no Whatsapp!</b>
        <br />
        Clique no botão para ser adicionado no grupo.
        <template v-if="error">
          <br />
          <span class="red--text">
            Ocorreu um erro ao tentar entrar no grupo.
          </span>
        </template>
      </span>

      <v-btn color="success" @click="join" :loading="loading">
        Entrar no grupo
      </v-btn>
    </div>
  </v-alert>
</template>

<script>
import TICKET from "@/services/app/ticket";

export default {
  data() {
    return {
      loading: false,
      s: false,
      error: false,
    };
  },
  methods: {
    async join() {
      try {
        this.error = false;
        this.loading = true;
        const response = await TICKET.whatsappGroupJoin(this.ticket.id);
        this.s = true;
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    ticket: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
