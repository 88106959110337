<template>
  <div v-if="attractions && attractions.length" id="periods">
    <h5>{{ title }}</h5>
    <v-timeline align-top dense>
      <template v-for="(period, i) in attractionsAndPeriods">
        <v-timeline-item
          v-if="period.type == 'period'"
          :key="`period-${period.index}`"
          :id="`period-${period.index + 1}`"
          hide-dot
          class="pb-0"
        >
          <v-divider v-if="i" class="mb-8" />
          <div class="d-flex flex-column align-center gap-0 mb-4">
            <h5 class="mb-0">
              <v-icon v-if="period.lock" small left color="error" class="mr-2">
                mdi-lock
              </v-icon>
              {{ period.name || `Período ${period.index + 1}` }}
            </h5>
            <small v-if="period.lock" class="error--text">
              Seu ingresso não dá acesso a esse período.
            </small>
            <span class="text-overline">
              {{ period | startEndDate("startDate", "endDate") }}
            </span>
            <p class="text-center mb-0" style="max-width: 500px">
              {{ period.description }}
            </p>
          </div>
          <div
            v-if="
              !hideEmptyPeriods &&
              (attractionsAndPeriods.length == i + 1 ||
                attractionsAndPeriods[i + 1].type == 'period')
            "
          >
            <v-alert type="info" text border="left" class="mt-1" dense>
              Não há atrações cadastradas para esse período.
            </v-alert>
          </div>
        </v-timeline-item>
        <attraction-row
          v-else
          :key="`attraction-${period.id}`"
          :attraction="period"
        />
      </template>
    </v-timeline>
  </div>
</template>

<script>
import moment from "moment";
import AttractionRow from "./AttractionRow.vue";
export default {
  components: { AttractionRow },
  methods: {
    sortAttractions(a, b) {
      if (a.timed && b.timed) return moment(a.start).diff(moment(b.start));
      if (a.timed) return -1;
      if (b.timed) return 1;
      return 0;
    },
  },
  computed: {
    attractionsAndPeriods() {
      const response = [];

      const periods = this.periods || [];

      if (!periods.length) return this.attractions.sort(this.sortAttractions);

      periods.forEach((period, i) => {
        const lock =
          this.tickets &&
          this.tickets.length &&
          !this.tickets.some((ticket) => {
            return period.TicketGroup.find(
              ({ id }) => id === ticket.TicketBlock.TicketGroup.id
            );
          });
        response.push({
          type: "period",
          index: i,
          ...period,
          lock,
        });

        this.attractions
          .filter((attraction) => attraction.periodId === period.id)
          .sort(this.sortAttractions)
          .forEach((attraction) => {
            response.push({
              type: "attraction",
              ...attraction,
            });
          });
      });

      //  invalidAttractions
      this.attractions
        .filter((attraction) => !attraction.periodId)
        .forEach((attraction) => {
          // add in start of array
          response.unshift({
            type: "attraction:invalid",
            ...attraction,
          });
        });

      return response;
    },
  },
  props: {
    attractions: {
      type: Array,
      required: true,
    },
    periods: {
      type: Array,
      default: () => [],
    },
    tickets: {
      type: Array | Boolean,
      default: () => [],
    },
    title: {
      type: String,
      default: "Cronograma",
    },
    hideEmptyPeriods: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
