module.exports = {
  filterMembership,
  cartList,
  totalValue,
  process: function process(cart) {
    const filterCart = filterMembership.bind({ cart })();
    const list = cartList.bind({ filterMembership: filterCart })();
    const value = totalValue.bind({ cartList: list })();

    return {
      list,
      totalValue: value,
    };
  },
};

function filterMembership() {
  var count = 0;

  const items = Object.values(this.cart || {})
    .sort((a, b) => {
      const aCanUse = a.ticketBlock.canUseMembership;
      const bCanUse = b.ticketBlock.canUseMembership;

      if (aCanUse) count++;
      if (aCanUse && !bCanUse) return -1;
      if (!aCanUse && bCanUse) return 1;
      if (aCanUse && bCanUse) {
        const aDiscount =
          a.ticketBlock.price - a.ticketBlock.membershipPrice[0].price;
        const bDiscount =
          b.ticketBlock.price - b.ticketBlock.membershipPrice[0].price;

        if (aDiscount > bDiscount) return -1;
        if (aDiscount < bDiscount) return 1;
        return 0;
      }
      return 0;
    })
    .map((item, i) => {
      return !i
        ? { ...item, biggestDiscount: !!count || undefined }
        : {
            ...item,
            ticketBlock: {
              ...item.ticketBlock,
              canUseMembership: false,
              membershipPrice: [],
            },
          };
    });
  return items;
}

function cartList() {
  const cart = [];

  this.filterMembership.forEach((item) => {
    const tables = [...(item.tables || [])];
    if (item.ticketBlock.canUseMembership) {
      cart.push({
        ...item,
        quantity: 1,
        table: tables.pop() || null,
      });
      if (item.quantity <= 1) return;
      tables.forEach((table) => {
        cart.push({
          ...item,
          quantity: 1,
          table: table,
          ticketBlock: {
            ...item.ticketBlock,
            canUseMembership: false,
            membershipPrice: [],
          },
        });
      });
      return;
    } else {
      if (tables.length > 0) {
        tables.forEach((table) => {
          cart.push({
            ...item,
            quantity: 1,
            table: table,
          });
        });
      } else {
        cart.push({
          ...item,
        });
      }
    }
  });

  return cart.filter((item) => item.quantity);
}

function totalValue() {
  return this.cartList.reduce(
    (acc, item) => {
      const quantity = item.quantity;
      const defaultPrice = item.ticketBlock.price;
      const price = item.ticketBlock.canUseMembership
        ? item.ticketBlock.membershipPrice[0].price
        : defaultPrice;

      const discount = defaultPrice - price;
      const fee = item.ticketBlock.fee || 0;

      acc.quantity += quantity;
      acc.total += (price + fee) * quantity;
      acc.discount += discount * quantity;

      return acc;
    },
    { quantity: 0, total: 0, discount: 0 }
  );
}
