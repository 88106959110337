<template>
  <div>
    <v-card
      v-for="purchase in purchases"
      :key="purchase.id"
      elevation="0"
      outlined
      class="d-flex justify-space-between align-center pa-2"
    >
      <div>
      </div>
      <div>
        <p class="mb-0 text-end">
          Válido de
          <b class="font-weight-medium">{{ purchase.validFrom | date }}</b>
        </p>
        <p class="mb-0 text-end">
          Até <b class="font-weight-medium">{{ purchase.validUntil | date }}</b>
        </p>
      </div>
    </v-card>
    <v-btn
      v-if="limit < filiation.MembershipPurchase.length"
      text
      block
      small
      @click="limit++"
      >Ver mais</v-btn
    >
  </div>
</template>

<script>
export default {
  data: () => ({
    limit: 1,
  }),
  computed: {
    purchases() {
      return this.filiation.MembershipPurchase.sort(
        (a, b) => new Date(b.validUntil) - new Date(a.validUntil)
      ).slice(0, this.limit);
    },
  },
  props: {
    filiation: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
